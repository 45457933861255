import { globalThis, document } from "./utils/server-safe-globals.js";
import { fullscreenApi } from "./utils/fullscreen-api.js";
import { containsComposedNode, getDocumentOrShadowRoot } from "./utils/element-utils.js";
import {
  hasVolumeSupportAsync,
  fullscreenSupported,
  pipSupported,
  airplaySupported,
  castSupported
} from "./utils/platform-tests.js";
import {
  MediaUIAttributes,
  TextTrackKinds,
  TextTrackModes,
  AvailabilityStates,
  StreamTypes
} from "./constants.js";
import {
  getTextTracksList,
  updateTracksModeTo,
  parseTracks
} from "./utils/captions.js";
let volumeSupported;
const volumeSupportPromise = hasVolumeSupportAsync().then((supported) => {
  volumeSupported = supported;
  return volumeSupported;
});
const StreamTypeValues = Object.values(StreamTypes);
const getSubtitleTracks = (controller) => {
  return getTextTracksList(controller.media, (textTrack) => {
    return [TextTrackKinds.SUBTITLES, TextTrackKinds.CAPTIONS].includes(textTrack.kind);
  }).sort((a, b) => a.kind >= b.kind ? 1 : -1);
};
const getShowingSubtitleTracks = (controller) => {
  return getTextTracksList(controller.media, (textTrack) => {
    return textTrack.mode === TextTrackModes.SHOWING && [TextTrackKinds.SUBTITLES, TextTrackKinds.CAPTIONS].includes(textTrack.kind);
  });
};
const MediaUIStates = {
  MEDIA_PAUSED: {
    get: function(controller) {
      const { media } = controller;
      return media ? media.paused : true;
    },
    mediaEvents: ["play", "playing", "pause", "emptied"]
  },
  MEDIA_HAS_PLAYED: {
    get: function(controller) {
      const { media } = controller;
      if (!media)
        return false;
      return !media.paused;
    },
    mediaEvents: ["playing", "emptied"]
  },
  MEDIA_ENDED: {
    get: function(controller) {
      const { media } = controller;
      return media ? media.ended : false;
    },
    mediaEvents: ["seeked", "ended", "emptied"]
  },
  MEDIA_PLAYBACK_RATE: {
    get: function(controller) {
      const { media } = controller;
      if (!media || typeof media.playbackRate == "undefined") {
        return 1;
      }
      return media.playbackRate;
    },
    mediaEvents: ["ratechange", "loadstart"]
  },
  MEDIA_MUTED: {
    get: function(controller) {
      const { media } = controller;
      if (!media || typeof media.muted == "undefined") {
        return false;
      }
      return media.muted;
    },
    mediaEvents: ["volumechange"]
  },
  MEDIA_VOLUME: {
    get: function(controller) {
      const { media } = controller;
      if (!media || typeof media.volume == "undefined") {
        return 1;
      }
      return Number(media.volume);
    },
    mediaEvents: ["volumechange"]
  },
  MEDIA_VOLUME_LEVEL: {
    get: function(controller) {
      const { media } = controller;
      let level = "high";
      if (!media || typeof media.volume == "undefined") {
        return level;
      }
      const { muted, volume } = media;
      if (volume === 0 || muted) {
        level = "off";
      } else if (volume < 0.5) {
        level = "low";
      } else if (volume < 0.75) {
        level = "medium";
      }
      return level;
    },
    mediaEvents: ["volumechange"]
  },
  MEDIA_CURRENT_TIME: {
    get: function(controller) {
      const { media } = controller;
      if (!media || typeof media.currentTime == "undefined") {
        return 0;
      }
      return media.currentTime;
    },
    mediaEvents: ["playing", "pause", "timeupdate", "loadedmetadata"]
  },
  MEDIA_DURATION: {
    get: function(controller) {
      const { media } = controller;
      if (controller.hasAttribute("defaultduration") && (!media || !media.duration || Number.isNaN(media.duration) || !Number.isFinite(media.duration))) {
        return +controller.getAttribute("defaultduration");
      }
      if (!media || !Number.isFinite(media.duration)) {
        return Number.NaN;
      }
      return media.duration;
    },
    mediaEvents: ["durationchange", "loadedmetadata", "emptied"]
  },
  MEDIA_SEEKABLE: {
    get: function(controller) {
      var _a;
      const { media } = controller;
      if (!((_a = media == null ? void 0 : media.seekable) == null ? void 0 : _a.length))
        return void 0;
      const start = media.seekable.start(0);
      const end = media.seekable.end(media.seekable.length - 1);
      if (!start && !end)
        return void 0;
      return [Number(start.toFixed(3)), Number(end.toFixed(3))];
    },
    mediaEvents: ["loadedmetadata", "emptied", "progress"]
  },
  MEDIA_LOADING: {
    get: function(controller) {
      var _a;
      return !!(((_a = controller.media) == null ? void 0 : _a.readyState) < 3);
    },
    mediaEvents: ["waiting", "playing", "emptied"]
  },
  MEDIA_BUFFERED: {
    get: function(controller) {
      var _a, _b, _c;
      const timeRanges = (_a = controller.media) == null ? void 0 : _a.buffered;
      return Array.from((_c = (_b = controller.media) == null ? void 0 : _b.buffered) != null ? _c : []).map((_, i) => [
        Number(timeRanges.start(i)).toFixed(3),
        Number(timeRanges.end(i)).toFixed(3)
      ]);
    },
    mediaEvents: ["progress", "emptied"]
  },
  MEDIA_STREAM_TYPE: {
    get: function(controller) {
      const { media } = controller;
      if (!media)
        return void 0;
      const { streamType } = media;
      if (StreamTypeValues.includes(streamType)) {
        if (streamType === StreamTypes.UNKNOWN) {
          const defaultType = controller.getAttribute("defaultstreamtype");
          if ([StreamTypes.LIVE, StreamTypes.ON_DEMAND].includes(defaultType)) {
            return defaultType;
          }
          return void 0;
        }
        return streamType;
      }
      const duration = media.duration;
      if (duration === Infinity) {
        return StreamTypes.LIVE;
      } else if (Number.isFinite(duration)) {
        return StreamTypes.ON_DEMAND;
      } else {
        const defaultType = controller.getAttribute("defaultstreamtype");
        if ([StreamTypes.LIVE, StreamTypes.ON_DEMAND].includes(defaultType)) {
          return defaultType;
        }
      }
      return void 0;
    },
    mediaEvents: [
      "emptied",
      "durationchange",
      "loadedmetadata",
      "streamtypechange"
    ]
  },
  MEDIA_TARGET_LIVE_WINDOW: {
    get: function(controller) {
      const { media } = controller;
      if (!media)
        return Number.NaN;
      const { targetLiveWindow } = media;
      const streamType = MediaUIStates.MEDIA_STREAM_TYPE.get(controller);
      if ((targetLiveWindow == null || Number.isNaN(targetLiveWindow)) && streamType === StreamTypes.LIVE) {
        return 0;
      }
      return targetLiveWindow;
    },
    mediaEvents: [
      "emptied",
      "durationchange",
      "loadedmetadata",
      "streamtypechange",
      "targetlivewindowchange"
    ]
  },
  MEDIA_TIME_IS_LIVE: {
    get: function(controller) {
      const { media } = controller;
      if (!media)
        return false;
      if (typeof media.liveEdgeStart === "number") {
        if (Number.isNaN(media.liveEdgeStart))
          return false;
        return media.currentTime >= media.liveEdgeStart;
      }
      const live = MediaUIStates.MEDIA_STREAM_TYPE.get(controller) === "live";
      if (!live)
        return false;
      const seekable = media.seekable;
      if (!seekable)
        return true;
      if (!seekable.length)
        return false;
      const liveEdgeStartOffset = controller.hasAttribute("liveedgeoffset") ? Number(controller.getAttribute("liveedgeoffset")) : 10;
      const liveEdgeStart = seekable.end(seekable.length - 1) - liveEdgeStartOffset;
      return media.currentTime >= liveEdgeStart;
    },
    mediaEvents: ["playing", "timeupdate", "progress", "waiting", "emptied"]
  },
  MEDIA_IS_FULLSCREEN: {
    get: function(controller, event) {
      var _a, _b;
      const media = controller.media;
      if (media && document[fullscreenApi.element] === void 0 && "webkitDisplayingFullscreen" in media) {
        return media.webkitDisplayingFullscreen && media.webkitPresentationMode === "fullscreen";
      }
      let fullscreenEl;
      if (event) {
        const isSomeElementFullscreen = document[fullscreenApi.element];
        fullscreenEl = isSomeElementFullscreen ? event.target : null;
      } else {
        fullscreenEl = (_b = (_a = getDocumentOrShadowRoot(controller)) == null ? void 0 : _a.fullscreenElement) != null ? _b : document[fullscreenApi.element];
      }
      return containsComposedNode(controller.fullscreenElement, fullscreenEl);
    },
    rootEvents: fullscreenApi.rootEvents,
    mediaEvents: fullscreenApi.mediaEvents
  },
  MEDIA_IS_PIP: {
    get: function(controller, e) {
      var _a;
      const media = controller.media;
      if (!media)
        return false;
      if (e) {
        return e.type == "enterpictureinpicture";
      } else {
        const pipElement = (_a = controller.getRootNode().pictureInPictureElement) != null ? _a : document.pictureInPictureElement;
        return containsComposedNode(media, pipElement);
      }
    },
    mediaEvents: ["enterpictureinpicture", "leavepictureinpicture"]
  },
  MEDIA_IS_AIRPLAYING: {
    get: function(controller) {
      var _a;
      const { media } = controller;
      if (!(media == null ? void 0 : media.remote) || ((_a = media.remote) == null ? void 0 : _a.state) === "disconnected")
        return false;
      return media.remote.state;
    },
    remoteEvents: ["playing", "connect", "connecting", "disconnect"]
  },
  MEDIA_IS_CASTING: {
    get: function(controller) {
      var _a;
      const { media } = controller;
      if (!(media == null ? void 0 : media.remote) || ((_a = media.remote) == null ? void 0 : _a.state) === "disconnected")
        return false;
      return media.remote.state;
    },
    remoteEvents: ["connect", "connecting", "disconnect"]
  },
  MEDIA_AIRPLAY_UNAVAILABLE: {
    get: function(controller, { availability = false } = {}) {
      if (!airplaySupported)
        return AvailabilityStates.UNSUPPORTED;
      if (availability == null || availability === true)
        return void 0;
      return AvailabilityStates.UNAVAILABLE;
    },
    mediaSetCallback(media, callback) {
      var _a;
      const isRemotePlaybackDisabled = media.disableRemotePlayback || media.hasAttribute("disableremoteplayback");
      if (!isRemotePlaybackDisabled) {
        (_a = media.remote) == null ? void 0 : _a.watchAvailability((availability) => callback({ availability })).catch((error) => {
          if (error.name === "NotSupportedError") {
            callback({ availability: null });
          } else {
            callback({ availability: false });
          }
        });
      }
    },
    mediaUnsetCallback(media) {
      var _a;
      const isRemotePlaybackDisabled = media.disableRemotePlayback || media.hasAttribute("disableremoteplayback");
      if (!isRemotePlaybackDisabled) {
        (_a = media.remote) == null ? void 0 : _a.cancelWatchAvailability();
      }
    }
  },
  MEDIA_CAST_UNAVAILABLE: {
    get: function(controller, { availability = false } = {}) {
      var _a;
      const { media } = controller;
      if (!castSupported || !((_a = media == null ? void 0 : media.remote) == null ? void 0 : _a.state)) {
        return AvailabilityStates.UNSUPPORTED;
      }
      if (availability == null || availability === true)
        return void 0;
      return AvailabilityStates.UNAVAILABLE;
    },
    mediaSetCallback(media, callback) {
      var _a;
      const isRemotePlaybackDisabled = media.disableRemotePlayback || media.hasAttribute("disableremoteplayback");
      if (!isRemotePlaybackDisabled) {
        (_a = media.remote) == null ? void 0 : _a.watchAvailability((availability) => callback({ availability })).catch((error) => {
          if (error.name === "NotSupportedError") {
            callback({ availability: null });
          } else {
            callback({ availability: false });
          }
        });
      }
    },
    mediaUnsetCallback(media) {
      var _a;
      const isRemotePlaybackDisabled = media.disableRemotePlayback || media.hasAttribute("disableremoteplayback");
      if (!isRemotePlaybackDisabled) {
        (_a = media.remote) == null ? void 0 : _a.cancelWatchAvailability();
      }
    }
  },
  MEDIA_FULLSCREEN_UNAVAILABLE: {
    get: function() {
      return fullscreenSupported ? void 0 : AvailabilityStates.UNAVAILABLE;
    }
  },
  MEDIA_PIP_UNAVAILABLE: {
    get: function() {
      return pipSupported ? void 0 : AvailabilityStates.UNSUPPORTED;
    }
  },
  MEDIA_RENDITION_UNAVAILABLE: {
    get: function(controller) {
      var _a;
      const { media } = controller;
      if (!(media == null ? void 0 : media.videoRenditions)) {
        return AvailabilityStates.UNSUPPORTED;
      }
      if (!((_a = media.videoRenditions) == null ? void 0 : _a.length)) {
        return AvailabilityStates.UNAVAILABLE;
      }
      return void 0;
    },
    mediaEvents: ["emptied", "loadstart"],
    videoRenditionsEvents: ["addrendition", "removerendition"]
  },
  MEDIA_AUDIO_TRACK_UNAVAILABLE: {
    get: function(controller) {
      var _a, _b;
      const { media } = controller;
      if (!(media == null ? void 0 : media.audioTracks)) {
        return AvailabilityStates.UNSUPPORTED;
      }
      if (((_b = (_a = media.audioTracks) == null ? void 0 : _a.length) != null ? _b : 0) <= 1) {
        return AvailabilityStates.UNAVAILABLE;
      }
      return void 0;
    },
    mediaEvents: ["emptied", "loadstart"],
    audioTracksEvents: ["addtrack", "removetrack"]
  },
  MEDIA_VOLUME_UNAVAILABLE: {
    get: function(controller) {
      if (volumeSupported !== void 0 && !volumeSupported) {
        return AvailabilityStates.UNSUPPORTED;
      }
      const { media } = controller;
      if (media && typeof media.volume == "undefined") {
        return AvailabilityStates.UNAVAILABLE;
      }
      return void 0;
    },
    mediaEvents: ["loadstart"]
  },
  MEDIA_SUBTITLES_LIST: {
    get: function(controller) {
      return getSubtitleTracks(controller).map(({ kind, label, language }) => ({ kind, label, language }));
    },
    mediaEvents: ["loadstart"],
    textTracksEvents: ["addtrack", "removetrack"]
  },
  MEDIA_SUBTITLES_SHOWING: {
    get: function(controller, event) {
      var _a;
      if (controller.hasAttribute("defaultsubtitles") && ["addtrack", "removetrack"].includes(event == null ? void 0 : event.type) && [TextTrackKinds.CAPTIONS, TextTrackKinds.SUBTITLES].includes((_a = event == null ? void 0 : event.track) == null ? void 0 : _a.kind)) {
        MediaUIRequestHandlers.MEDIA_TOGGLE_SUBTITLES_REQUEST(void 0, { detail: true }, controller);
      }
      return getShowingSubtitleTracks(controller).map(({ kind, label, language }) => ({ kind, label, language }));
    },
    mediaEvents: ["loadstart"],
    textTracksEvents: ["addtrack", "removetrack", "change"]
  },
  MEDIA_CHAPTERS_CUES: {
    get: function(controller) {
      var _a;
      const { media } = controller;
      if (!media)
        return [];
      const [chaptersTrack] = getTextTracksList(media, { kind: TextTrackKinds.CHAPTERS });
      return Array.from((_a = chaptersTrack == null ? void 0 : chaptersTrack.cues) != null ? _a : []).map(({ text, startTime, endTime }) => ({ text, startTime, endTime }));
    },
    mediaEvents: ["loadstart", "loadedmetadata"],
    textTracksEvents: ["addtrack", "removetrack"]
  },
  MEDIA_RENDITION_LIST: {
    get: function(controller) {
      var _a;
      const { media } = controller;
      return [...(_a = media == null ? void 0 : media.videoRenditions) != null ? _a : []];
    },
    mediaEvents: ["emptied", "loadstart"],
    videoRenditionsEvents: ["addrendition", "removerendition"]
  },
  MEDIA_RENDITION_SELECTED: {
    get: function(controller) {
      var _a, _b, _c;
      const { media } = controller;
      return (_c = (_b = media == null ? void 0 : media.videoRenditions) == null ? void 0 : _b[(_a = media.videoRenditions) == null ? void 0 : _a.selectedIndex]) == null ? void 0 : _c.id;
    },
    mediaEvents: ["emptied"],
    videoRenditionsEvents: ["addrendition", "removerendition", "change"]
  },
  MEDIA_AUDIO_TRACK_LIST: {
    get: function(controller) {
      var _a;
      const { media } = controller;
      return [...(_a = media == null ? void 0 : media.audioTracks) != null ? _a : []];
    },
    mediaEvents: ["emptied", "loadstart"],
    audioTracksEvents: ["addtrack", "removetrack"]
  },
  MEDIA_AUDIO_TRACK_ENABLED: {
    get: function(controller) {
      var _a, _b;
      const { media } = controller;
      return (_b = [...(_a = media == null ? void 0 : media.audioTracks) != null ? _a : []].find((audioTrack) => audioTrack.enabled)) == null ? void 0 : _b.id;
    },
    mediaEvents: ["emptied"],
    audioTracksEvents: ["addtrack", "removetrack", "change"]
  }
};
const MediaUIRequestHandlers = {
  MEDIA_PLAY_REQUEST: (media, event, controller) => {
    var _a;
    const streamType = MediaUIStates.MEDIA_STREAM_TYPE.get(controller);
    const autoSeekToLive = controller.getAttribute("noautoseektolive") === null;
    if (streamType == StreamTypes.LIVE && autoSeekToLive) {
      MediaUIRequestHandlers["MEDIA_SEEK_TO_LIVE_REQUEST"](media);
    }
    (_a = media.play()) == null ? void 0 : _a.catch(() => {
    });
  },
  MEDIA_PAUSE_REQUEST: (media) => media.pause(),
  MEDIA_MUTE_REQUEST: (media) => media.muted = true,
  MEDIA_UNMUTE_REQUEST: (media) => {
    media.muted = false;
    if (media.volume === 0) {
      media.volume = 0.25;
    }
  },
  MEDIA_VOLUME_REQUEST: (media, event, mediaController) => {
    const volume = event.detail;
    media.volume = volume;
    if (volume > 0 && media.muted) {
      media.muted = false;
    }
    if (!mediaController.hasAttribute("novolumepref")) {
      try {
        globalThis.localStorage.setItem(
          "media-chrome-pref-volume",
          volume.toString()
        );
      } catch (err) {
      }
    }
  },
  MEDIA_ENTER_FULLSCREEN_REQUEST: (media, event, controller) => {
    if (!fullscreenSupported) {
      console.warn(
        "MediaChrome: Fullscreen support is unavailable; not entering fullscreen"
      );
      return;
    }
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
    if (controller[fullscreenApi.enter]) {
      controller.fullscreenElement[fullscreenApi.enter]();
    } else if (media.webkitEnterFullscreen) {
      media.webkitEnterFullscreen();
    } else if (media.requestFullscreen) {
      media.requestFullscreen();
    } else {
      console.warn("MediaChrome: Fullscreen not supported");
    }
  },
  MEDIA_EXIT_FULLSCREEN_REQUEST: () => {
    document[fullscreenApi.exit]();
  },
  MEDIA_ENTER_PIP_REQUEST: (media) => {
    if (!document.pictureInPictureEnabled) {
      console.warn("MediaChrome: Picture-in-picture is not enabled");
      return;
    }
    if (!media.requestPictureInPicture) {
      console.warn(
        "MediaChrome: The current media does not support picture-in-picture"
      );
      return;
    }
    if (document[fullscreenApi.element]) {
      document[fullscreenApi.exit]();
    }
    const warnNotReady = () => {
      console.warn(
        "MediaChrome: The media is not ready for picture-in-picture. It must have a readyState > 0."
      );
    };
    media.requestPictureInPicture().catch((err) => {
      if (err.code === 11) {
        if (media.readyState === 0 && media.preload === "none") {
          const cleanup = () => {
            media.removeEventListener("loadedmetadata", tryPip);
            media.preload = "none";
          };
          const tryPip = () => {
            media.requestPictureInPicture().catch(warnNotReady);
            cleanup();
          };
          media.addEventListener("loadedmetadata", tryPip);
          media.preload = "metadata";
          setTimeout(() => {
            if (media.readyState === 0)
              warnNotReady();
            cleanup();
          }, 1e3);
        } else {
          throw err;
        }
      } else {
        throw err;
      }
    });
  },
  MEDIA_EXIT_PIP_REQUEST: () => {
    if (document.pictureInPictureElement) {
      document.exitPictureInPicture();
    }
  },
  MEDIA_ENTER_CAST_REQUEST: async (media) => {
    var _a;
    if (((_a = media.remote) == null ? void 0 : _a.state) !== "disconnected")
      return;
    if (document[fullscreenApi.element]) {
      document[fullscreenApi.exit]();
    }
    if (!media.remote.prompt) {
      console.warn("MediaChrome: Casting is not supported in this environment");
      return;
    }
    try {
      await media.remote.prompt();
    } catch {
    }
  },
  MEDIA_EXIT_CAST_REQUEST: (media) => {
    var _a;
    if (((_a = media.remote) == null ? void 0 : _a.state) !== "connected")
      return;
    media.remote.prompt();
  },
  MEDIA_SEEK_REQUEST: (media, event) => {
    const time = event.detail;
    if (media.readyState > 0 || media.readyState === void 0) {
      media.currentTime = time;
    }
  },
  MEDIA_PLAYBACK_RATE_REQUEST: (media, event) => {
    media.playbackRate = event.detail;
  },
  MEDIA_PREVIEW_REQUEST: (media, event, controller) => {
    var _a, _b, _c, _d, _e, _f;
    if (!media)
      return;
    const time = event.detail;
    if (time === null) {
      controller.propagateMediaState(MediaUIAttributes.MEDIA_PREVIEW_TIME, void 0);
      controller.propagateMediaState(MediaUIAttributes.MEDIA_PREVIEW_IMAGE, void 0);
      controller.propagateMediaState(MediaUIAttributes.MEDIA_PREVIEW_COORDS, void 0);
      return;
    }
    controller.propagateMediaState(MediaUIAttributes.MEDIA_PREVIEW_TIME, time);
    const [chaptersTrack] = getTextTracksList(media, {
      kind: TextTrackKinds.CHAPTERS
    });
    const chapterCue = Array.from((_a = chaptersTrack == null ? void 0 : chaptersTrack.cues) != null ? _a : []).find((c) => c.startTime <= time && c.endTime > time);
    controller.propagateMediaState(MediaUIAttributes.MEDIA_PREVIEW_CHAPTER, chapterCue == null ? void 0 : chapterCue.text);
    const [thumbsTrack] = getTextTracksList(media, {
      kind: TextTrackKinds.METADATA,
      label: "thumbnails"
    });
    const thumbCue = Array.from((_b = thumbsTrack == null ? void 0 : thumbsTrack.cues) != null ? _b : []).find((c) => c.startTime >= time);
    if (!thumbCue)
      return;
    const base = !/'^(?:[a-z]+:)?\/\//i.test((_c = thumbCue == null ? void 0 : thumbCue.text) != null ? _c : "") ? (_d = media.querySelector('track[label="thumbnails"]')) == null ? void 0 : _d.src : void 0;
    const url = new URL((_e = thumbCue == null ? void 0 : thumbCue.text) != null ? _e : "./", base);
    const previewCoordsStr = (_f = new URLSearchParams(url.hash).get("#xywh")) != null ? _f : "";
    controller.propagateMediaState(MediaUIAttributes.MEDIA_PREVIEW_IMAGE, url.href);
    controller.propagateMediaState(MediaUIAttributes.MEDIA_PREVIEW_COORDS, previewCoordsStr.split(","));
  },
  MEDIA_SHOW_SUBTITLES_REQUEST: (_media, event, controller) => {
    var _a;
    const tracks = getSubtitleTracks(controller);
    const { detail = [] } = event;
    const tracksToUpdate = parseTracks(detail);
    const preferredLanguage = (_a = tracksToUpdate[0]) == null ? void 0 : _a.language;
    if (preferredLanguage && !(controller == null ? void 0 : controller.hasAttribute("nosubtitleslangpref"))) {
      globalThis.localStorage.setItem(
        "media-chrome-pref-subtitles-lang",
        preferredLanguage
      );
    }
    updateTracksModeTo(TextTrackModes.SHOWING, tracks, tracksToUpdate);
  },
  MEDIA_DISABLE_SUBTITLES_REQUEST: (_media, event, controller) => {
    const tracks = getSubtitleTracks(controller);
    const { detail: tracksToUpdate = [] } = event;
    updateTracksModeTo(TextTrackModes.DISABLED, tracks, tracksToUpdate);
  },
  MEDIA_TOGGLE_SUBTITLES_REQUEST: (_media, event, controller) => {
    const { detail: force } = event != null ? event : {};
    const tracks = getSubtitleTracks(controller);
    const showingSubitleTracks = getShowingSubtitleTracks(controller);
    const subtitlesShowing = !!showingSubitleTracks.length;
    if (!tracks.length)
      return;
    if (force === false || subtitlesShowing && force !== true) {
      updateTracksModeTo(TextTrackModes.DISABLED, tracks, showingSubitleTracks);
    } else if (force === true || !subtitlesShowing && force !== false) {
      let subTrack = tracks[0];
      if (!(controller == null ? void 0 : controller.hasAttribute("nosubtitleslangpref"))) {
        const subtitlesPref = globalThis.localStorage.getItem("media-chrome-pref-subtitles-lang");
        const userLangPrefs = subtitlesPref ? [subtitlesPref, ...globalThis.navigator.languages] : globalThis.navigator.languages;
        const preferredAvailableSubs = tracks.filter((textTrack) => {
          return userLangPrefs.some((lang) => textTrack.language.toLowerCase().startsWith(lang.split("-")[0]));
        }).sort((textTrackA, textTrackB) => {
          const idxA = userLangPrefs.findIndex((lang) => textTrackA.language.toLowerCase().startsWith(lang.split("-")[0]));
          const idxB = userLangPrefs.findIndex((lang) => textTrackB.language.toLowerCase().startsWith(lang.split("-")[0]));
          return idxA - idxB;
        });
        if (preferredAvailableSubs[0]) {
          subTrack = preferredAvailableSubs[0];
        }
      }
      const { language, label, kind } = subTrack;
      updateTracksModeTo(TextTrackModes.DISABLED, tracks, showingSubitleTracks);
      updateTracksModeTo(
        TextTrackModes.SHOWING,
        tracks,
        [{ language, label, kind }]
      );
    }
  },
  MEDIA_AIRPLAY_REQUEST: async (media) => {
    if (!(media == null ? void 0 : media.remote))
      return;
    if (!media.remote.prompt) {
      console.warn("MediaChrome: AirPlay is not supported in this environment");
      return;
    }
    try {
      await media.remote.prompt();
    } catch {
    }
  },
  MEDIA_SEEK_TO_LIVE_REQUEST: (media) => {
    const seekable = media.seekable;
    if (!seekable) {
      console.warn("MediaChrome: Media element does not support seeking to live.");
      return;
    }
    if (!seekable.length) {
      console.warn("MediaChrome: Media is unable to seek to live.");
      return;
    }
    media.currentTime = seekable.end(seekable.length - 1);
  },
  MEDIA_RENDITION_REQUEST: (media, event) => {
    if (!(media == null ? void 0 : media.videoRenditions)) {
      console.warn("MediaChrome: Rendition selection not supported by this media.");
      return;
    }
    const renditionId = event.detail;
    const index = [...media.videoRenditions].findIndex((r) => r.id == renditionId);
    if (media.videoRenditions.selectedIndex != index) {
      media.videoRenditions.selectedIndex = index;
    }
  },
  MEDIA_AUDIO_TRACK_REQUEST: (media, event) => {
    if (!(media == null ? void 0 : media.audioTracks)) {
      console.warn("MediaChrome: Audio track selection not supported by this media.");
      return;
    }
    const audioTrackId = event.detail;
    for (let track of media.audioTracks) {
      track.enabled = audioTrackId == track.id;
    }
  }
};
export {
  MediaUIRequestHandlers,
  MediaUIStates,
  volumeSupportPromise
};
