import { shallowEqualObjects } from "shallow-equal";
import fullpage from "fullpage.js";
import "./volumeFader.js";

import "./issue-02--video.js";
import "./issue-02--audio.js";

const handleLeave = (destination) => {
  if (destination.item.querySelector(".slide-title--color--white")) {
    document.getElementById("paradigm").classList.add("header--white");
    document.getElementById("paradigm").classList.remove("header--black");
  } else {
    document.getElementById("paradigm").classList.add("header--black");
    document.getElementById("paradigm").classList.remove("header--white");
  }
};

const handleSectionLeave = (origin, destination, direction, trigger) => {
  handleLeave(destination);

  // AUDIO
  // skip stopping audio for these anchor sections
  const skipFadeSections = ["love-wins", "further-reading", "credits"];
  const skipFade = skipFadeSections.includes(destination.anchor);
  // find all currently playing audio elements and fade out
  const previousAudioElements = [...document.querySelectorAll("audio")].filter(
    (audioElement) => {
      return !audioElement.paused;
    }
  );
  if (previousAudioElements && !skipFade) {
    previousAudioElements.forEach((previousAudioElement) => {
      const volumeFader = new VolumeFader(previousAudioElement);
      volumeFader.fadeOut(() => {
        previousAudioElement.pause();
        previousAudioElement.currentTime = 0;
      });
    });
  }
  // fade in audio element found in new section
  const newAudioElement = destination.item.querySelector("audio");
  if (newAudioElement) {
    if (newAudioElement.paused) {
      const volumeFader = new VolumeFader(newAudioElement);
      newAudioElement.play();
      volumeFader.fadeIn();
    }
  }
  // END AUDIO

  // SKIP HIDDEN SECTIONS
  if (destination.item.style.display === "none") {
    let slide = direction === "down" ? origin.index + 3 : origin.index - 1;
    fullpage_api.moveTo(slide);

    return false; // cancel slide that triggered this onLeave
  }
};

const handleSlideLeave = (section, origin, destination) => {
  handleLeave(destination);
};

const issueConfiguration = {
  // Configure FullPage.js
  fullpageConfiguration: {
    licenseKey: "AB4FFBAC-A2D84139-A3F2DB62-E3A0D3D9",
    autoScrolling: true,
    scrollHorizontally: true,
    controlArrows: false,
    verticalCentered: false,
    continuousVertical: true,
    resetSliders: false,
    lazyLoading: true,
    dragAndMove: true,
    normalScrollElements:
      "#s04-machine, #s10-the-top-tabs-interview, #s15-gadget, #s26-vibrant-matter",
    anchors: [
      "cover",
      "mother",
      "man-vs-machine",
      "algorithm-creativity",
      "machine",
      "the-user-mindset",
      "utopia-quiz",
      "utopia-quiz-results",
      "call-of-data",
      "monetizable-pathology",
      "digitail-amnesia-built-in-forgetting",
      "the-top-tabs-interview",
      "corporate-culture",
      "job-descriptions-of-the-future",
      "auto-pilot-fiction",
      "postcore",
      "gadget",
      "fantasy-vs-supply-chain-and-demand",
      "dopamine-fast",
      "man",
      "framing-fluidity",
      "lo-fi-is-sci-fi",
      "in-defense-of-the-poor-image",
      "cultural-clock",
      "be-a-body",
      "vibrant-matter-text",
      "this-that",
      "vibrant-matter-editorial",
      "true-power-lies-in-intention",
      "love-wins",
      "further-reading",
      "credits",
      "back-cover",
    ],
    menu: "#menu",
    // Track Current Section
    onLeave: handleSectionLeave,
    // Track Current Slide
    onSlideLeave: handleSlideLeave,
  },
};

const {
  grayParadigmTextSectionSlides,
  whiteBackgroundSectionSlides,
  fullpageConfiguration,
} = issueConfiguration;

const initialize = (isDevelopment) => {
  var fullPageInstance = new fullpage("#fullpage", fullpageConfiguration);

  // Get 'Normal' Scrolling Sections
  const machineSection = document.getElementById("s04-machine");
  const topTabsSection = document.getElementById("s10-the-top-tabs-interview");
  const gadgetSection = document.getElementById("s15-gadget");
  const vibrantMatterSection = document.getElementById("s26-vibrant-matter");

  [machineSection, topTabsSection, gadgetSection, vibrantMatterSection].forEach(
    (sectionElement) => {
      // Add Event Listeners to Sections
      sectionElement.addEventListener(
        "swiped-down",
        fullpage_api.moveSectionUp,
        false
      );
      sectionElement.addEventListener(
        "swiped-up",
        fullpage_api.moveSectionDown,
        false
      );
    }
  );
};

export default initialize;
