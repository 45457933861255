import "@mux/mux-player"
import "./swiped-events.min.js";

/* Issue Specific Settings */
import issue01 from "./issue01.js";
import issue02 from "./issue02.js";
import issue03 from "./issue03.js";

const initialize = () => {
  const isDevelopment = process.env.NODE_ENV === "development";

  const issueNumber = document
    .getElementsByTagName("body")[0]
    .getAttribute("id")
    .replace("issue-", "");

  switch (issueNumber) {
    case "1":
      issue01(isDevelopment);
      break;
    case "2":
      issue02(isDevelopment);
      break;
    case "3":
      issue03(isDevelopment);
      break;
    default:
      console.log("No issue found");
  }
};

initialize();
