const initialize = () => {
  const audioElements = document.querySelectorAll("audio");

  [...audioElements].forEach((audioElement) => {
    const volumeFader = new VolumeFader(audioElement);
    const audioControlsButtonElement = audioElement.parentNode.querySelector(
      ".audio-controls button"
    );

    if (audioControlsButtonElement) {
      audioControlsButtonElement.addEventListener("click", () => {
        if (audioElement.paused) {
          volumeFader.fadeIn();
          audioElement.play();
        } else {
          audioControlsButtonElement.innerHTML = `<svg width="100%" height="100%" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;height:2rem;">
          <path d="M9.33,6.69L9.33,19.39L19.3,13.04L9.33,6.69Z" style="fill-rule:nonzero;"/>
          <path d="M26,13C26,20.132 20.132,26 13,26C5.868,26 0,20.132 0,13C-0,5.868 5.868,0 13,0C20.132,-0 26,5.868 26,13ZM13,2.18C12.983,2.18 12.967,2.18 12.95,2.18C6.976,2.18 2.06,7.096 2.06,13.07C2.06,19.044 6.976,23.96 12.95,23.96C18.924,23.96 23.84,19.044 23.84,13.07C23.84,13.067 23.84,13.063 23.84,13.06C23.835,7.109 18.951,2.207 13,2.18Z" style="fill-rule:nonzero;"/>
      </svg>`;
          volumeFader.fadeOut(() => {
            audioElement.pause();
            audioElement.currentTime = 0;
          });
        }
      });
    }

    audioElement.addEventListener("play", (event) => {
      if (audioControlsButtonElement) {
        audioControlsButtonElement.classList.add("is-playing");
        audioControlsButtonElement.innerHTML = `<svg width="100%" height="100%" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;height:2rem;">
        <g transform="matrix(1,0,0,1,3.35,-1.77636e-15)">
            <g transform="matrix(2.11667,0,0,2.11667,-18.75,-12.36)">
                <path d="M13.945,9.019L15,9L15,15L13.945,15.019L13.945,9.019Z" style="fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(2.11667,0,0,2.11667,-12.75,-12.36)">
                <path d="M9,9L10.055,9.019L10.055,15.019L9,15L9,9Z" style="fill-rule:nonzero;"/>
            </g>
        </g>
        <path d="M26,13C26,20.132 20.132,26 13,26C5.868,26 0,20.132 0,13C-0,5.868 5.868,0 13,0C20.132,-0 26,5.868 26,13ZM13,2.18C12.983,2.18 12.967,2.18 12.95,2.18C6.976,2.18 2.06,7.096 2.06,13.07C2.06,19.044 6.976,23.96 12.95,23.96C18.924,23.96 23.84,19.044 23.84,13.07C23.84,13.067 23.84,13.063 23.84,13.06C23.835,7.109 18.951,2.207 13,2.18Z" style="fill-rule:nonzero;"/>
    </svg>
    `;
      }
    });

    audioElement.addEventListener("pause", (event) => {
      if (audioControlsButtonElement) {
        audioControlsButtonElement.classList.remove("is-playing");
        audioControlsButtonElement.innerHTML = `<svg width="100%" height="100%" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;height:2rem;">
    <path d="M9.33,6.69L9.33,19.39L19.3,13.04L9.33,6.69Z" style="fill-rule:nonzero;"/>
    <path d="M26,13C26,20.132 20.132,26 13,26C5.868,26 0,20.132 0,13C-0,5.868 5.868,0 13,0C20.132,-0 26,5.868 26,13ZM13,2.18C12.983,2.18 12.967,2.18 12.95,2.18C6.976,2.18 2.06,7.096 2.06,13.07C2.06,19.044 6.976,23.96 12.95,23.96C18.924,23.96 23.84,19.044 23.84,13.07C23.84,13.067 23.84,13.063 23.84,13.06C23.835,7.109 18.951,2.207 13,2.18Z" style="fill-rule:nonzero;"/>
</svg>`;
      }
      console.log("pause", event);
    });

    audioElement.addEventListener("ended", (event) => {
      if (audioControlsButtonElement) {
        audioControlsButtonElement.classList.remove("is-playing");
        audioControlsButtonElement.innerHTML = `<svg width="100%" height="100%" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;height:2rem;">
    <path d="M9.33,6.69L9.33,19.39L19.3,13.04L9.33,6.69Z" style="fill-rule:nonzero;"/>
    <path d="M26,13C26,20.132 20.132,26 13,26C5.868,26 0,20.132 0,13C-0,5.868 5.868,0 13,0C20.132,-0 26,5.868 26,13ZM13,2.18C12.983,2.18 12.967,2.18 12.95,2.18C6.976,2.18 2.06,7.096 2.06,13.07C2.06,19.044 6.976,23.96 12.95,23.96C18.924,23.96 23.84,19.044 23.84,13.07C23.84,13.067 23.84,13.063 23.84,13.06C23.835,7.109 18.951,2.207 13,2.18Z" style="fill-rule:nonzero;"/>
</svg>`;
      }
      console.log("ended", event);
    });
  });
};

initialize();
