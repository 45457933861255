var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _mediaController, _keysSoFar, _clearKeysTimeout, _metaPressed, _clickListener, _handleKeyListener, handleKeyListener_fn, _keyupListener, _keydownListener, _updateLayoutStyle, updateLayoutStyle_fn, _getOption, getOption_fn, _selectOption, selectOption_fn, _searchOption, searchOption_fn, _clearKeysOnDelay, clearKeysOnDelay_fn;
import { MediaStateReceiverAttributes } from "../constants.js";
import { globalThis, document } from "../utils/server-safe-globals.js";
const checkIcon = `
<svg aria-hidden="true" viewBox="0 1 24 24" part="select-indicator indicator">
  <path d="m10 15.17 9.193-9.191 1.414 1.414-10.606 10.606-6.364-6.364 1.414-1.414 4.95 4.95Z"/>
</svg>`;
function createOption(text, value, selected) {
  const option = document.createElement("media-chrome-option");
  option.part.add("option");
  option.value = value;
  option.selected = selected;
  const label = document.createElement("span");
  label.textContent = text;
  option.append(label);
  return option;
}
function createIndicator(el, name) {
  let customIndicator = el.querySelector(`:scope > [slot="${name}"]`);
  if ((customIndicator == null ? void 0 : customIndicator.nodeName) == "SLOT")
    customIndicator = customIndicator.assignedElements({ flatten: true })[0];
  if (customIndicator) {
    customIndicator = customIndicator.cloneNode(true);
    customIndicator.removeAttribute("slot");
    return customIndicator;
  }
  let fallbackIndicator = el.shadowRoot.querySelector(`[name="${name}"] > svg`);
  return fallbackIndicator.cloneNode(true);
}
const template = document.createElement("template");
template.innerHTML = `
<style>
  :host {
    font: var(--media-font,
      var(--media-font-weight, normal)
      var(--media-font-size, 15px) /
      var(--media-text-content-height, var(--media-control-height, 24px))
      var(--media-font-family, helvetica neue, segoe ui, roboto, arial, sans-serif));
    color: var(--media-text-color, var(--media-primary-color, rgb(238 238 238)));
    background: var(--media-listbox-background, var(--media-control-background, var(--media-secondary-color, rgb(20 20 30 / .8))));
    border-radius: var(--media-listbox-border-radius);
    display: inline-flex;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
  }

  ::slotted([slot="header"]) {
    padding: .4em 1.4em;
    border-bottom: 1px solid rgb(255 255 255 / .25);
  }

  #container {
    gap: var(--media-listbox-gap);
    display: flex;
    flex-direction: var(--media-listbox-flex-direction, column);
    overflow: hidden auto;
    padding-block: .5em;
  }

  media-chrome-option {
    padding-inline: .7em 1.4em;
  }

  media-chrome-option > span {
    margin-inline: .5ch;
  }

  [part~="indicator"] {
    fill: var(--media-option-indicator-fill, var(--media-icon-color, var(--media-primary-color, rgb(238 238 238))));
    height: var(--media-option-indicator-height, 1.25em);
    vertical-align: var(--media-option-indicator-vertical-align, text-top);
  }

  [part~="select-indicator"] {
    display: var(--media-option-select-indicator-display);
    visibility: hidden;
  }

  [aria-selected="true"] > [part~="select-indicator"] {
    visibility: visible;
  }
</style>
<style id="layout-row" media="width:0">

  ::slotted([slot="header"]) {
    padding: .4em .5em;
  }

  #container {
    gap: var(--media-listbox-gap, .25em);
    flex-direction: var(--media-listbox-flex-direction, row);
    padding-inline: .5em;
  }

  media-chrome-option {
    padding: .3em .24em;
  }

  media-chrome-option[aria-selected="true"] {
    background: var(--media-option-selected-background, rgb(255 255 255 / .2));
  }

  [part~="select-indicator"] {
    display: var(--media-option-select-indicator-display, none);
  }
</style>
<slot name="header"></slot>
<slot id="container"></slot>
<slot name="select-indicator" hidden>${checkIcon}</slot>
`;
class MediaChromeListbox extends globalThis.HTMLElement {
  constructor(options = {}) {
    super();
    __privateAdd(this, _handleKeyListener);
    __privateAdd(this, _updateLayoutStyle);
    __privateAdd(this, _getOption);
    __privateAdd(this, _selectOption);
    __privateAdd(this, _searchOption);
    __privateAdd(this, _clearKeysOnDelay);
    __privateAdd(this, _mediaController, void 0);
    __privateAdd(this, _keysSoFar, "");
    __privateAdd(this, _clearKeysTimeout, null);
    __privateAdd(this, _metaPressed, false);
    __privateAdd(this, _clickListener, (e) => {
      this.handleClick(e);
    });
    __privateAdd(this, _keyupListener, (e) => {
      const { key } = e;
      if (key === "Escape") {
        this.removeEventListener("keyup", __privateGet(this, _keyupListener));
        return;
      }
      if (key === "Meta") {
        __privateSet(this, _metaPressed, false);
        return;
      }
      __privateMethod(this, _handleKeyListener, handleKeyListener_fn).call(this, e);
    });
    __privateAdd(this, _keydownListener, (e) => {
      const { key, altKey } = e;
      if (altKey) {
        this.removeEventListener("keyup", __privateGet(this, _keyupListener));
        return;
      }
      if (key === "Meta") {
        __privateSet(this, _metaPressed, true);
        return;
      }
      if (this.keysUsed.includes(key)) {
        e.preventDefault();
      }
      if (__privateGet(this, _metaPressed) && this.keysUsed.includes(key)) {
        __privateMethod(this, _handleKeyListener, handleKeyListener_fn).call(this, e);
        return;
      }
      this.addEventListener("keyup", __privateGet(this, _keyupListener), { once: true });
    });
    if (!this.shadowRoot) {
      this.attachShadow({ mode: "open" });
      this.nativeEl = template.content.cloneNode(true);
      if (options.slotTemplate) {
        this.nativeEl.append(options.slotTemplate.content.cloneNode(true));
      }
      this.shadowRoot.append(this.nativeEl);
    }
    this.container = this.shadowRoot.querySelector("#container");
    this.container.addEventListener("slotchange", (event) => {
      for (let node of event.target.assignedNodes({ flatten: true })) {
        if (node.nodeType === 3 && node.textContent.trim() === "") {
          node.remove();
        }
      }
    });
  }
  static get observedAttributes() {
    return ["disabled", "style", MediaStateReceiverAttributes.MEDIA_CONTROLLER];
  }
  static formatOptionText(text) {
    return text;
  }
  formatOptionText(text, data) {
    return this.constructor.formatOptionText(text, data);
  }
  get options() {
    var _a;
    let options = this.querySelectorAll("media-chrome-option");
    if (!options.length) {
      options = (_a = this.container) == null ? void 0 : _a.querySelectorAll("media-chrome-option");
    }
    return Array.from(options);
  }
  get selectedOptions() {
    return this.options.filter((option) => option.selected);
  }
  get value() {
    var _a, _b;
    return (_b = (_a = this.selectedOptions[0]) == null ? void 0 : _a.value) != null ? _b : "";
  }
  set value(newValue) {
    const option = this.options.find((option2) => option2.value === newValue);
    if (!option)
      return;
    __privateMethod(this, _selectOption, selectOption_fn).call(this, option);
  }
  focus() {
    var _a;
    (_a = this.selectedOptions[0]) == null ? void 0 : _a.focus();
  }
  enable() {
    this.addEventListener("click", __privateGet(this, _clickListener));
    this.addEventListener("keydown", __privateGet(this, _keydownListener));
  }
  disable() {
    this.removeEventListener("click", __privateGet(this, _clickListener));
    this.removeEventListener("keyup", __privateGet(this, _keyupListener));
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    var _a, _b, _c, _d, _e;
    if (attrName === "style" && newValue !== oldValue) {
      __privateMethod(this, _updateLayoutStyle, updateLayoutStyle_fn).call(this);
    } else if (attrName === MediaStateReceiverAttributes.MEDIA_CONTROLLER) {
      if (oldValue) {
        (_b = (_a = __privateGet(this, _mediaController)) == null ? void 0 : _a.unassociateElement) == null ? void 0 : _b.call(_a, this);
        __privateSet(this, _mediaController, null);
      }
      if (newValue && this.isConnected) {
        __privateSet(this, _mediaController, (_c = this.getRootNode()) == null ? void 0 : _c.getElementById(newValue));
        (_e = (_d = __privateGet(this, _mediaController)) == null ? void 0 : _d.associateElement) == null ? void 0 : _e.call(_d, this);
      }
    } else if (attrName === "disabled" && newValue !== oldValue) {
      if (newValue == null) {
        this.enable();
      } else {
        this.disable();
      }
    }
  }
  connectedCallback() {
    var _a, _b, _c;
    __privateMethod(this, _updateLayoutStyle, updateLayoutStyle_fn).call(this);
    if (!this.hasAttribute("disabled")) {
      this.enable();
    }
    if (!this.hasAttribute("role")) {
      this.setAttribute("role", "listbox");
    }
    const mediaControllerId = this.getAttribute(
      MediaStateReceiverAttributes.MEDIA_CONTROLLER
    );
    if (mediaControllerId) {
      __privateSet(this, _mediaController, (_a = this.getRootNode()) == null ? void 0 : _a.getElementById(mediaControllerId));
      (_c = (_b = __privateGet(this, _mediaController)) == null ? void 0 : _b.associateElement) == null ? void 0 : _c.call(_b, this);
    }
  }
  disconnectedCallback() {
    var _a, _b;
    this.disable();
    (_b = (_a = __privateGet(this, _mediaController)) == null ? void 0 : _a.unassociateElement) == null ? void 0 : _b.call(_a, this);
    __privateSet(this, _mediaController, null);
  }
  get keysUsed() {
    return ["Enter", " ", "ArrowDown", "ArrowUp", "Home", "End"];
  }
  handleSelection(e, toggle) {
    const option = __privateMethod(this, _getOption, getOption_fn).call(this, e);
    if (!option)
      return;
    __privateMethod(this, _selectOption, selectOption_fn).call(this, option, toggle);
  }
  handleMovement(e) {
    const { key } = e;
    const els = this.options;
    let currentOption = __privateMethod(this, _getOption, getOption_fn).call(this, e);
    if (!currentOption) {
      currentOption = els.filter((el) => el.getAttribute("tabindex") === "0")[0];
    }
    let nextOption;
    switch (key) {
      case "ArrowDown":
        nextOption = currentOption.nextElementSibling;
        if (nextOption == null ? void 0 : nextOption.hasAttribute("disabled")) {
          nextOption = nextOption.nextElementSibling;
        }
        break;
      case "ArrowUp":
        nextOption = currentOption.previousElementSibling;
        if (nextOption == null ? void 0 : nextOption.hasAttribute("disabled")) {
          nextOption = nextOption.previousElementSibling;
        }
        break;
      case "Home":
        nextOption = els[0];
        break;
      case "End":
        nextOption = els[els.length - 1];
        break;
      default:
        nextOption = __privateMethod(this, _searchOption, searchOption_fn).call(this, key);
        break;
    }
    if (nextOption) {
      els.forEach((el) => el.setAttribute("tabindex", "-1"));
      nextOption.setAttribute("tabindex", "0");
      nextOption.focus();
    }
  }
  handleClick(e) {
    const option = __privateMethod(this, _getOption, getOption_fn).call(this, e);
    if (!option || option.hasAttribute("disabled"))
      return;
    this.options.forEach((el) => el.setAttribute("tabindex", "-1"));
    option.setAttribute("tabindex", "0");
    this.handleSelection(e, this.hasAttribute("aria-multiselectable") && this.getAttribute("aria-multiselectable") === "true");
  }
}
_mediaController = new WeakMap();
_keysSoFar = new WeakMap();
_clearKeysTimeout = new WeakMap();
_metaPressed = new WeakMap();
_clickListener = new WeakMap();
_handleKeyListener = new WeakSet();
handleKeyListener_fn = function(e) {
  const { key } = e;
  if (key === "Enter" || key === " ") {
    this.handleSelection(e, this.hasAttribute("aria-multiselectable") && this.getAttribute("aria-multiselectable") === "true");
  } else {
    this.handleMovement(e);
  }
};
_keyupListener = new WeakMap();
_keydownListener = new WeakMap();
_updateLayoutStyle = new WeakSet();
updateLayoutStyle_fn = function() {
  var _a;
  const layoutRowStyle = this.shadowRoot.querySelector("#layout-row");
  const isLayoutRow = ((_a = getComputedStyle(this).getPropertyValue("--media-listbox-layout")) == null ? void 0 : _a.trim()) === "row";
  layoutRowStyle.setAttribute("media", isLayoutRow ? "" : "width:0");
};
_getOption = new WeakSet();
getOption_fn = function(e) {
  const composedPath = e.composedPath();
  const index = composedPath.findIndex((el) => el.nodeName === "MEDIA-CHROME-OPTION");
  return composedPath[index];
};
_selectOption = new WeakSet();
selectOption_fn = function(option, toggle) {
  const oldSelectedOptions = [...this.selectedOptions];
  if (!this.hasAttribute("aria-multiselectable") || this.getAttribute("aria-multiselectable") !== "true") {
    this.options.forEach((el) => el.selected = false);
  }
  if (toggle) {
    option.selected = !option.selected;
  } else {
    option.selected = true;
  }
  if (this.selectedOptions.some((opt, i) => opt != oldSelectedOptions[i])) {
    this.dispatchEvent(new Event("change", { bubbles: true, composed: true }));
  }
};
_searchOption = new WeakSet();
searchOption_fn = function(key) {
  __privateMethod(this, _clearKeysOnDelay, clearKeysOnDelay_fn).call(this);
  const els = this.options;
  const activeIndex = els.findIndex((el) => el.getAttribute("tabindex") === "0");
  __privateSet(this, _keysSoFar, __privateGet(this, _keysSoFar) + key);
  const repeatedKey = __privateGet(this, _keysSoFar).split("").every((k) => k === key);
  const after = els.slice(activeIndex + (repeatedKey ? 1 : 0)).filter((el) => el.textContent.toLowerCase().startsWith(__privateGet(this, _keysSoFar)));
  const before = els.slice(0, activeIndex - (repeatedKey ? 1 : 0)).filter((el) => el.textContent.toLowerCase().startsWith(__privateGet(this, _keysSoFar)));
  let afterRepeated = [];
  let beforeRepeated = [];
  if (repeatedKey) {
    afterRepeated = els.slice(activeIndex + (repeatedKey ? 1 : 0)).filter((el) => el.textContent.startsWith(key));
    beforeRepeated = els.slice(0, activeIndex - (repeatedKey ? 1 : 0)).filter((el) => el.textContent.startsWith(key));
  }
  const returns = [...after, ...before, ...afterRepeated, ...beforeRepeated];
  return returns[0];
};
_clearKeysOnDelay = new WeakSet();
clearKeysOnDelay_fn = function() {
  clearTimeout(__privateGet(this, _clearKeysTimeout));
  __privateSet(this, _clearKeysTimeout, null);
  __privateSet(this, _clearKeysTimeout, setTimeout(() => {
    __privateSet(this, _keysSoFar, "");
    __privateSet(this, _clearKeysTimeout, null);
  }, 500));
};
if (!globalThis.customElements.get("media-chrome-listbox")) {
  globalThis.customElements.define("media-chrome-listbox", MediaChromeListbox);
}
var media_chrome_listbox_default = MediaChromeListbox;
export {
  MediaChromeListbox,
  createIndicator,
  createOption,
  media_chrome_listbox_default as default
};
