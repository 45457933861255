import store from "store2";
import { nanoid } from "nanoid";
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

// Configure Firebase
const firebaseConfig = {
  apiKey: "AIzaSyCLq-vWAeoJzYUIriRAz1NM9QhUhDvq0UA",
  authDomain: "paradigm-magazine.firebaseapp.com",
  projectId: "paradigm-magazine",
  storageBucket: "paradigm-magazine.appspot.com",
  messagingSenderId: "866346591242",
  appId: "1:866346591242:web:e6517a854fba5be692ebf3",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

// Create or Retrieve Device ID and Session ID
const deviceId = store.get("deviceId");
if (!deviceId) {
  store.set("deviceId", nanoid());
}
const sessionId = nanoid();

// Configure Application State
const state = {
  totalSections: document.querySelectorAll(".issue-03--section").length,
  activeSection: 0,
  subtitleTimer: {},
  sectionClickEvent: {},
};

const deactivateAllSections = () => {
  const sections = document.querySelectorAll(".issue-03--section");
  sections.forEach((section) => {
    section.classList.remove("active");
    // reset section videos and state
    const video = section.querySelectorAll("mux-player");
    video.forEach((video) => {
      video.pause();
      video.currentTime = 0;
    });
    const form = section.querySelector("form");
    if (form) {
      form.classList.remove("active");
    }
  });
};

// This Function Updates Which Section is Currently Active
const updateSection = (newSection) => {
  console.log("newSection", newSection);
  // deactivate all sections by removing the .active class from all elements with class .section
  replaceText("");
  deactivateAllSections();
  // activate the selected section by finding the element with class .section and the data-section attribute equal to the section number
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${newSection}"]`
  );
  activeSection.classList.add("active");
  // find the active video element and play it
  const video = activeSection.querySelectorAll("mux-player");
  video.forEach((video) => {
    video.play();
  });
  // update the state to reflect the active section
  state.activeSection = newSection;
};

const handleSubmitForm = async (e) => {
  e.preventDefault();
  // Get Form Data
  const form = e.target;
  const section = form.getAttribute("data-section");
  const answer = form.querySelector(".questionnaire--input").value;
  // Submit Answer to Firebase
  await submitAnswerFirebaseDocument({ section, answer });
  // Add Answer to LocalStorage for results page
  localStorage.setItem(`section-${section}`, answer);
  // Place answer in results page
  // const answerContainer = document.querySelector(
  //   `#section-question-${section}`
  // );
  // answerContainer.innerHTML = answer;
  // update section if there is a next section
  if (state.activeSection < state.totalSections) {
    updateSection(state.activeSection + 1);
  } else {
    // if there is no next section, we have reached the end of the issue
    // hide the questionnaire in the final section
    form.style.display = "none";
    // deactivateAllSections();
    // document.querySelector(".issue-03--results").classList.add("active");
  }
};

const submitAnswerFirebaseDocument = async ({ section, answer }) => {
  try {
    const docRef = await addDoc(collection(db, "answers"), {
      deviceId: deviceId,
      sessionId: sessionId,
      section: section,
      answer: answer,
    });
    console.log("Document written with ID: ", docRef.id);
  } catch (e) {
    console.error("Error adding document: ", e);
  }
};

const beginLoadingAnimation = () => {
  document.querySelector(".progress").classList.add("is-loading");
  // plays loading animation on desktop, waits for click on mobile
  if (window.innerWidth <= 768) {
    // nothing on mobile
    const handleClick = () => {
      document.querySelector(".progress").classList.add("is-loaded");
      document.querySelector(".loading--loader").classList.remove("active");
      document.querySelector(".loading--content").classList.add("active");
      document.querySelector(".loader--background").classList.add("blur");
      loadingElement.removeEventListener("click", handleClick);
    }
    const loadingElement = document.querySelector(".loading.active");
    if(loadingElement) {
      loadingElement.addEventListener("click", handleClick);

    }
  } else {
    setTimeout(() => {
      document.querySelector(".progress").classList.add("is-loaded");
      document.querySelector(".loading--loader").classList.remove("active");
      document.querySelector(".loading--content").classList.add("active");
      document.querySelector(".loader--background").classList.add("blur");
    }, 2000);
  }
};

// const addProgressToBar = () => {
//   if (state.barProgress <= 100) {
//     document.querySelector('.progress').setAttribute("value", state.barProgress)
//     state.barProgress = state.barProgress + 2
//   } else {
//     document.querySelector('.loading--loader').classList.remove('active')
//     document.querySelector('.loading--content').classList.add('active')
//   }
// }

const handleNavigation = (event) => {
  event.preventDefault();
  event.stopPropagation();
  const li = event.target.closest("li");
  const index = Array.from(li.closest("ul").children).indexOf(li);
  const newSection = index + 1;
  if (state.activeSection !== newSection) {
    updateSection(newSection);
  }
  console.log("handleNavigation");
  hideNavigation();
};

// const showVideoContext = (event) => {
//   const activeSection = document.querySelector(
//     `.issue-03--section[data-section="${state.activeSection}"]`
//   );
//   // get button's data-section attribute, replace video credits + video content with correct section content
//   const hoveredSection = event.target.getAttribute("data-section");
//   activeSection
//     .querySelector(".video--context")
//     .querySelector(`[data-context='${hoveredSection - 1}']`)
//     .classList.add("active");
// };

// const hideVideoContext = () => {
//   const activeSection = document.querySelector(
//     `.issue-03--section[data-section="${state.activeSection}"]`
//   );
//   console.log("activeSection", activeSection);
//   // get button's data-section attribute, replace video credits + video content with correct section content
//   const activeContext = activeSection.querySelector(".video--context .active");
//   if (activeContext) {
//     activeContext.classList.remove("active");
//   }
// };

// const showVideoCredits = (event) => {
//   const activeSection = document.querySelector(
//     `.issue-03--section[data-section="${state.activeSection}"]`
//   );
//   // get button's data-section attribute, replace video credits + video content with correct section content
//   const hoveredSection = event.target.getAttribute("data-section");
//   if (hoveredSection) {
//     activeSection
//       .querySelector(".video--credits")
//       .querySelector(`[data-credits='${hoveredSection - 1}']`)
//       .classList.add("active");
//   }
// };

// const hideVideoCredits = () => {
//   const activeSection = document.querySelector(
//     `.issue-03--section[data-section="${state.activeSection}"]`
//   );
//   const activeCredits = activeSection.querySelector(".video--context--and--credits .active");
//   if (activeCredits) {
//     activeCredits.classList.remove("active");
//   }
// };

const showContextAndCredits = (event) => {
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${state.activeSection}"]`
  );
  const hoveredSection = event.target.getAttribute("data-section");
  if (hoveredSection) {
    activeSection
      .querySelector(".video--context--and--credits")
      .querySelector(`[data-context-and-credits='${hoveredSection - 1}']`)
      .classList.add("active");
  }
};

const hideContextAndCredits = () => {
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${state.activeSection}"]`
  );
  if (activeSection) {
    const activeContextAndCredits = activeSection.querySelector(
      ".video--context--and--credits .active"
    );
    if (activeContextAndCredits) {
      activeContextAndCredits.classList.remove("active");
    }
  }
};

const handleVideoEnd = (event) => {
  const section = event.target.closest(".issue-03--section");
  const form = section.querySelector("form");
  if (form) {
    form.classList.add("active");
  } else {
    if (state.activeSection < state.totalSections) {
      updateSection(state.activeSection + 1);
    } else {
      // if there is no next section, we have reached the end of the issue
      // hide the questionnaire in the final section
      // deactivateAllSections();
      // document.querySelector(".issue-03--results").classList.add("active");
    }
  }
  hideText();
  replaceText("");
};

const showNavigation = () => {
  console.log("showNavigation");
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${state.activeSection}"]`
  );
  activeSection.querySelector(".video--label").classList.add("active");
  const activeSubtitles = activeSection.querySelector(
    ".video--subtitles.active"
  );
  if (activeSubtitles) {
    activeSubtitles.classList.remove("active");
  }
  const activeContextAndCredits = activeSection.querySelector(
    ".video--context--and--credits"
  );
  const activeContextAndCreditsMobile = activeSection.querySelector(
    ".video--context--and--credits.mobile"
  );
  if (activeContextAndCredits) {
    activeContextAndCredits.classList.add("active");
  }
  if (activeContextAndCreditsMobile) {
    activeContextAndCreditsMobile.classList.add("active");
  }
};

const hideNavigation = () => {
  console.log("hideNavigation");
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${state.activeSection}"]`
  );
  const activeVideoLabel = document.querySelector(".video--label.active");
  if (activeVideoLabel) {
    activeVideoLabel.classList.remove("active");
  }
  // document.querySelector(".video--subtitles").classList.add("active");
  const activeSubtitles = activeSection.querySelector(".video--subtitles");
  if (activeSubtitles) {
    activeSubtitles.classList.add("active");
  }
  const activeContextAndCredits = document.querySelector(
    ".video--context--and--credits.active"
  );
  if (activeContextAndCredits) {
    activeContextAndCredits.classList.remove("active");
  }
  const activeContextAndCreditsMobile = activeSection.querySelector(
    ".video--context--and--credits.mobile.active"
  );
  if (activeContextAndCreditsMobile) {
    activeContextAndCreditsMobile.classList.remove("active");
  }
};

// When on Desktop, manually brings up the 'Loading' screen that is always visible on mobile
const initializeLoader = () => {
  const loader = document.querySelector(".loading");
  loader.classList.add("active");
  beginLoadingAnimation();
};

const replaceText = (text) => {
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${state.activeSection}"]`
  );
  if (activeSection) {
    const subtitleElement = activeSection.querySelector(".video--subtitles");
    subtitleElement.innerHTML = text;
  }
};

const showText = () => {
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${state.activeSection}"]`
  );
  const subtitleElement = activeSection.querySelector(".video--subtitles");
  clearTimeout(state.subtitleTimer);
  state.subtitleTimer = setTimeout(() => {
    hideText();
  }, 5000);
  subtitleElement.style.display = "block";
};

const hideText = () => {
  const activeSection = document.querySelector(
    `.issue-03--section[data-section="${state.activeSection}"]`
  );
  const subtitleElement = activeSection.querySelector(".video--subtitles");
  subtitleElement.style.display = "none";
};

const cueEnter = (event) => {
  replaceText(event.target.text);
  showText();
};

const cueExit = () => {
  hideText();
};

const initialize = (isDevelopment) => {
  console.log("initializing issue 03");
  console.log("isDevelopment: ", isDevelopment);

  const urlParams = new URLSearchParams(window.location.search);
  console.log("urlParams", urlParams);
  const startWithLoader = urlParams.get("start_with_loader");

  if (startWithLoader) {
    initializeLoader();
  }

  // when document is ready, add the class 'running' to the div.javascript in vanilla javascript no jquery
  document.querySelector(".javascript").classList.add("running");

  // when in development, 'S' key will skip to the end of the video
  if (isDevelopment) {
    document.addEventListener("keydown", (event) => {
      if (event.key === "s") {
        const activeSection = document.querySelector(
          `.issue-03--section[data-section="${state.activeSection}"]`
        );
        const video = activeSection.querySelector("mux-player");
        video.currentTime = video.duration;
        replaceText("");
      }
    });
  }

  const initialSize = window.innerWidth;

  if (initialSize <= 768) {
    document.querySelectorAll(".video").forEach((videoElement) => {
      videoElement.remove();
    });
    document.querySelectorAll(".blurred-video").forEach((videoElement) => {
      videoElement.remove();
    });
    initializeLoader();
  } else {
    document.querySelectorAll(".video-mobile").forEach((videoElement) => {
      videoElement.remove();
    });
    document
      .querySelectorAll(".blurred-video-mobile")
      .forEach((videoElement) => {
        videoElement.remove();
      });
  }

  // add event listener to open the experience if you open it in a desktop view
  const desktopInitializeButtonElement = document.querySelector(
    "#season-3--initialize-desktop"
  );
  if (desktopInitializeButtonElement) {
    desktopInitializeButtonElement.addEventListener("click", initializeLoader);
  }

  // add event listener to move the user to the first section
  const beginSessionButtonElement = document.querySelector(
    "#season-3--begin-session"
  );

  beginSessionButtonElement.addEventListener("click", () => {
    const loader = document.querySelector(".loading");
    const cover = document.querySelector("#s00-cover");
    loader.classList.add("loaded");
    cover.classList.add("loaded");
    updateSection(1);
  });

  // add event listeners to all form submission elements
  const formElements = document.querySelectorAll("form");
  formElements.forEach((form) => {
    form.addEventListener("submit", handleSubmitForm);
  });

  // add event listeners to .video--label
  const videoLabelElements = document.querySelectorAll(".video--label");

  videoLabelElements.forEach((videoLabelElement) => {
    videoLabelElement.addEventListener("click", (event) => {
      event.stopPropagation();
      showNavigation();
    });
    videoLabelElement.addEventListener("mouseover", (event) => {
      event.stopPropagation();
      showNavigation();
    });
    videoLabelElement.addEventListener("mouseout", (event) => {
      event.stopPropagation();
      hideNavigation();
    });
  });

  // add event listeners to all buttons in every ul element that has class navigation
  const navigationElements = document.querySelectorAll(".navigation");
  navigationElements.forEach((navigation) => {
    navigation.addEventListener("click", handleNavigation);
    if (window.innerWidth > 768) {
      navigation.addEventListener("mouseover", showContextAndCredits);
      navigation.addEventListener("mouseout", hideContextAndCredits);
    }
    // navigation.addEventListener("mouseover", showVideoCredits);
    // navigation.addEventListener("mouseout", hideVideoCredits);
  });

  // add event listeners to backgrounds of sections to close
  // const sections = document.querySelectorAll('.issue-03--section')

  // sections.forEach((section) => {
  //   section.addEventListener("click", (event) => {
  //     event.stopPropagation();
  //     // if nagivation is open, hide
  //     const activeNavigation = document.querySelector('.video--label.active')
  //     if(activeNavigation) {
  //       console.log('clicking on section, hiding navigation')
  //       hideNavigation();
  //     }
  //   });
  // })

  // TO DO: Add Event Listeners to All Videos 'ended' Events
  const videoElements = document.querySelectorAll(".video mux-player");
  videoElements.forEach((video) => {
    video.addEventListener("ended", handleVideoEnd);
  });
  // lol "dont repeat yourself" ahahhahahah
  const mobileVideoElements = document.querySelectorAll(
    ".video-mobile mux-player"
  );
  mobileVideoElements.forEach((video) => {
    video.addEventListener("ended", handleVideoEnd);
  });

  // Add Event Listeners to Allow Custom Presentation of Subtitles
  videoElements.forEach((video) => {
    const videoLoaded = (event) => {
      const video = event.target;
      const tracks = video.textTracks;
      const track = Array.from(tracks).find(
        (track) => track.kind === "subtitles"
      );
      if (track) {
        track.mode = "hidden";
        const cues = track.cues;
        Array.from(cues).forEach((cue) => {
          cue.addEventListener("enter", cueEnter);
          cue.addEventListener("exit", cueExit);
        });
      }
    };

    video.addEventListener("loadedmetadata", videoLoaded);
  });
  mobileVideoElements.forEach((video) => {
    const videoLoaded = (event) => {
      const video = event.target;
      const tracks = video.textTracks;
      const track = Array.from(tracks).find(
        (track) => track.kind === "subtitles"
      );
      if (track) {
        track.mode = "hidden";
        const cues = track.cues;
        Array.from(cues).forEach((cue) => {
          cue.addEventListener("enter", cueEnter);
          cue.addEventListener("exit", cueExit);
        });
      }
    };

    video.addEventListener("loadedmetadata", videoLoaded);
  });
};

export default initialize;
