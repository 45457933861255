import { shallowEqualObjects } from "shallow-equal";
import fullpage from "fullpage.js";

// Track State of Section and Slide
const currentSectionSlide = {
  section: 0,
  slide: 0,
};

const setCurrentSection = (section) => {
  currentSectionSlide.section = section;
  handleFullpagePositionChange();
};

const setCurrentSlide = (slide) => {
  currentSectionSlide.slide = slide;
  handleFullpagePositionChange();
};

const handleFullpagePositionChange = () => {
  updateParadigmLogoTextColor();
};

const updateParadigmLogoTextColor = () => {
  if (
    !issueConfiguration.grayParadigmTextSectionSlides.includes(
      issueConfiguration.grayParadigmTextSectionSlides.find(
        ({ section, slide }) => {
          return (
            section === currentSectionSlide.section &&
            slide === currentSectionSlide.slide
          );
        }
      )
    )
  ) {
    document.getElementById("paradigm").classList.add("header--black");
  } else {
    document.getElementById("paradigm").classList.remove("header--black");
  }
};

const updateBackgroundColor = () => {
  if (
    issueConfiguration.whiteBackgroundSectionSlides.includes(
      issueConfiguration.whiteBackgroundSectionSlides.find(
        ({ section, slide }) => {
          return (
            section === currentSectionSlide.section &&
            slide === currentSectionSlide.slide
          );
        }
      )
    )
  ) {
    document.getElementById("paradigm").classList.remove("dark");
  } else {
    document.getElementById("paradigm").classList.add("dark");
  }
};

const issueConfiguration = {
  // Section:Slide Pairs that change Slide Title text to Gray Text
  grayParadigmTextSectionSlides: [
    { section: 0, slide: 0 },
    { section: 2, slide: 0 },
    { section: 2, slide: 3 },
    { section: 2, slide: 4 },
    { section: 2, slide: 5 },
    { section: 2, slide: 6 },
    { section: 2, slide: 7 },
    { section: 2, slide: 8 },
    { section: 2, slide: 9 },
    { section: 11, slide: 0 },
  ],

  // Section:Slide Pairs that Set the Background to White Glow
  whiteBackgroundSectionSlides: [{ section: 2, slide: 2 }],

  // Configure FullPage.js
  fullpageConfiguration: {
    licenseKey: "AB4FFBAC-A2D84139-A3F2DB62-E3A0D3D9",
    autoScrolling: true,
    scrollHorizontally: true,
    controlArrows: false,
    verticalCentered: false,
    continuousVertical: true,
    resetSliders: false,
    lazyLoading: true,
    dragAndMove: true,
    normalScrollElements: "#high-n-low, #referenz, #kitsch, #love-letter",
    anchors: [
      "00-desktop--anchor",
      "01-how-stalin-predicted-tik-tok--anchor",
      "02-the-new-creative-paradigm--anchor",
      "03-open-source-is-god--anchor",
      "04-on-memes--anchor",
      "05-high-n-low--anchor",
      "06-referenz--anchor",
      "07-avantgarde--anchor",
      "08-kitsch--anchor",
      "09-love-letter--anchor",
      "10-further-reading--anchor",
      "11-credits--anchor",
    ],
    menu: "#menu",
    // Track Current Section
    onLeave: (origin, destination) => {
      setCurrentSection(destination.index);
    },
    // Track Current Slide
    onSlideLeave: function (section, origin, destination) {
      setCurrentSlide(destination.index);
    },
  },
};

const {
  grayParadigmTextSectionSlides,
  whiteBackgroundSectionSlides,
  fullpageConfiguration,
} = issueConfiguration;

const initialize = (isDevelopment) => {
  // initialize fullpage js
  var fullPageInstance = new fullpage("#fullpage", fullpageConfiguration);

  // Get Sections
  let highLowSectionElement = document.getElementById("s05-high-n-low");
  let referenzSectionElement = document.getElementById("s06-referenz");
  let avantgardeSectionElement = document.getElementById("s07-avantgarde");
  let kitschSectionElement = document.getElementById("s08-kitsch");

  [
    highLowSectionElement,
    referenzSectionElement,
    avantgardeSectionElement,
    kitschSectionElement,
  ].forEach((sectionElement) => {
    // Add Event Listeners to Sections
    sectionElement.addEventListener(
      "swiped-down",
      fullpage_api.moveSectionUp,
      false
    );
    sectionElement.addEventListener(
      "swiped-up",
      fullpage_api.moveSectionDown,
      false
    );
  });
};

export default initialize;
