import { MediaChromeSelectMenu } from "./media-chrome-selectmenu.js";
import "./media-audio-track-button.js";
import "./media-audio-track-listbox.js";
import { MediaUIAttributes } from "../constants.js";
import { globalThis, document } from "../utils/server-safe-globals.js";
class MediaAudioTrackSelectMenu extends MediaChromeSelectMenu {
  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      MediaUIAttributes.MEDIA_AUDIO_TRACK_ENABLED,
      MediaUIAttributes.MEDIA_AUDIO_TRACK_UNAVAILABLE
    ];
  }
  init() {
    const audioTrackButton = document.createElement("media-audio-track-button");
    audioTrackButton.part.add("button");
    audioTrackButton.preventClick = true;
    const audioTrackListbox = document.createElement("media-audio-track-listbox");
    audioTrackListbox.part.add("listbox");
    audioTrackListbox.setAttribute("exportparts", "option, option-selected, indicator");
    const buttonSlot = this.shadowRoot.querySelector("slot[name=button]");
    const listboxSlot = this.shadowRoot.querySelector("slot[name=listbox]");
    buttonSlot.textContent = "";
    listboxSlot.textContent = "";
    buttonSlot.append(audioTrackButton);
    listboxSlot.append(audioTrackListbox);
  }
}
if (!globalThis.customElements.get("media-audio-track-selectmenu")) {
  globalThis.customElements.define("media-audio-track-selectmenu", MediaAudioTrackSelectMenu);
}
var media_audio_track_selectmenu_default = MediaAudioTrackSelectMenu;
export {
  MediaAudioTrackSelectMenu,
  media_audio_track_selectmenu_default as default
};
