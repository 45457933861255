const initialize = () => {
  const videoElements = document.querySelectorAll("video");

  [...videoElements].forEach((videoElement) => {
    const videoControlsButtonElement = videoElement.parentNode.querySelector(
      ".video-controls button"
    );

    if (videoControlsButtonElement) {
      videoControlsButtonElement.addEventListener("click", () => {
        if (videoElement.paused) {
          videoElement.play();
        } else {
          videoElement.pause();
        }
      });
    }

    videoElement.addEventListener("play", () => {
      if (videoControlsButtonElement) {
        videoControlsButtonElement.classList.add("is-playing");
      }
    });

    videoElement.addEventListener("pause", () => {
      if (videoControlsButtonElement) {
        videoControlsButtonElement.classList.remove("is-playing");
      }
    });

    videoElement.addEventListener("ended", () => {
      if (videoControlsButtonElement) {
        videoControlsButtonElement.classList.remove("is-playing");
      }
    });

    videoElement.addEventListener("click", () => {
      if (videoElement.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    });
  });
};

initialize();
