var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _renditionList, _prevState, _render, render_fn, _onChange, onChange_fn;
import { MediaChromeListbox, createOption, createIndicator } from "./media-chrome-listbox.js";
import "./media-chrome-option.js";
import { globalThis } from "../utils/server-safe-globals.js";
import { getStringAttr, setStringAttr } from "../utils/element-utils.js";
import { parseRenditionList } from "../utils/utils.js";
import { MediaUIAttributes, MediaUIEvents } from "../constants.js";
class MediaRenditionListbox extends MediaChromeListbox {
  constructor() {
    super(...arguments);
    __privateAdd(this, _render);
    __privateAdd(this, _onChange);
    __privateAdd(this, _renditionList, []);
    __privateAdd(this, _prevState, void 0);
  }
  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      MediaUIAttributes.MEDIA_RENDITION_LIST,
      MediaUIAttributes.MEDIA_RENDITION_SELECTED
    ];
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    super.attributeChangedCallback(attrName, oldValue, newValue);
    if (attrName === MediaUIAttributes.MEDIA_RENDITION_SELECTED && oldValue !== newValue) {
      this.value = newValue != null ? newValue : "auto";
    } else if (attrName === MediaUIAttributes.MEDIA_RENDITION_LIST && oldValue !== newValue) {
      __privateSet(this, _renditionList, parseRenditionList(newValue));
      __privateMethod(this, _render, render_fn).call(this);
    }
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("change", __privateMethod(this, _onChange, onChange_fn));
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("change", __privateMethod(this, _onChange, onChange_fn));
  }
  get mediaRenditionList() {
    return __privateGet(this, _renditionList);
  }
  set mediaRenditionList(list) {
    __privateSet(this, _renditionList, list);
    __privateMethod(this, _render, render_fn).call(this);
  }
  get mediaRenditionSelected() {
    return getStringAttr(this, MediaUIAttributes.MEDIA_RENDITION_SELECTED);
  }
  set mediaRenditionSelected(id) {
    setStringAttr(this, MediaUIAttributes.MEDIA_RENDITION_SELECTED, id);
  }
}
_renditionList = new WeakMap();
_prevState = new WeakMap();
_render = new WeakSet();
render_fn = function() {
  if (__privateGet(this, _prevState) === JSON.stringify(this.mediaRenditionList))
    return;
  __privateSet(this, _prevState, JSON.stringify(this.mediaRenditionList));
  const renditionList = this.mediaRenditionList.sort((a, b) => b.height - a.height);
  const container = this.shadowRoot.querySelector("#container");
  container.textContent = "";
  let isAuto = !this.mediaRenditionSelected;
  for (const rendition of renditionList) {
    const text = this.formatOptionText(
      `${Math.min(rendition.width, rendition.height)}p`,
      rendition
    );
    const option2 = createOption(
      text,
      `${rendition.id}`,
      rendition.selected && !isAuto
    );
    option2.prepend(createIndicator(this, "select-indicator"));
    container.append(option2);
  }
  const option = createOption(this.formatOptionText("Auto"), "auto", isAuto);
  option.prepend(createIndicator(this, "select-indicator"));
  container.append(option);
};
_onChange = new WeakSet();
onChange_fn = function() {
  if (this.value == null)
    return;
  const event = new globalThis.CustomEvent(
    MediaUIEvents.MEDIA_RENDITION_REQUEST,
    {
      composed: true,
      bubbles: true,
      detail: this.value
    }
  );
  this.dispatchEvent(event);
};
if (!globalThis.customElements.get("media-rendition-listbox")) {
  globalThis.customElements.define("media-rendition-listbox", MediaRenditionListbox);
}
var media_rendition_listbox_default = MediaRenditionListbox;
export {
  MediaRenditionListbox,
  media_rendition_listbox_default as default
};
