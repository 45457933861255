var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _mediaController, _button, _buttonSlot, _listbox, _listboxSlot, _keyupListener, _keydownListener, _documentClickHandler, _clickHandler, _handleOptionChange, _toggle, toggle_fn, _show, show_fn, _hide, hide_fn, _updateMenuPosition;
import "../media-chrome-button.js";
import "./media-chrome-listbox.js";
import { globalThis, document } from "../utils/server-safe-globals.js";
import { containsComposedNode, closestComposedNode, getOrInsertCSSRule, getActiveElement } from "../utils/element-utils.js";
import { observeResize, unobserveResize } from "../utils/resize-observer.js";
import { MediaStateReceiverAttributes } from "../constants.js";
const template = document.createElement("template");
template.innerHTML = `
  <style>
  :host {
    font: var(--media-font,
      var(--media-font-weight, normal)
      var(--media-font-size, 15px) /
      var(--media-text-content-height, var(--media-control-height, 24px))
      var(--media-font-family, helvetica neue, segoe ui, roboto, arial, sans-serif));
    color: var(--media-text-color, var(--media-primary-color, rgb(238 238 238)));
    display: inline-flex;
    position: relative;
    flex-shrink: .5;
  }

  [name=listbox]::slotted(*),
  [part=listbox] {
    position: absolute;
    bottom: 100%;
    max-height: 300px;
    transition: var(--media-selectmenu-transition-in,
      visibility 0s, transform .15s ease-out, opacity .15s ease-out);
    transform: var(--media-listbox-transform-in, translateY(0) scale(1));
    visibility: visible;
    opacity: 1;
  }

  [name=listbox][hidden]::slotted(*),
  [hidden] [part=listbox] {
    transition: var(--media-selectmenu-transition-out,
      visibility .15s ease-out, transform .15s ease-out, opacity .15s ease-out);
    transform: var(--media-listbox-transform-out, translateY(2px) scale(.99));
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  }

  slot[name=listbox][hidden] {
    display: block;
  }
  </style>

  <slot name="button">
    <media-chrome-button aria-haspopup="listbox" part="button">
      <slot name="button-content"></slot>
    </media-chrome-button>
  </slot>
  <slot name="listbox" hidden>
    <media-chrome-listbox id="listbox" part="listbox">
      <slot></slot>
    </media-chrome-listbox>
  </slot>
`;
class MediaChromeSelectMenu extends globalThis.HTMLElement {
  constructor() {
    var _a;
    super();
    __privateAdd(this, _toggle);
    __privateAdd(this, _show);
    __privateAdd(this, _hide);
    __privateAdd(this, _mediaController, void 0);
    __privateAdd(this, _button, void 0);
    __privateAdd(this, _buttonSlot, void 0);
    __privateAdd(this, _listbox, void 0);
    __privateAdd(this, _listboxSlot, void 0);
    __privateAdd(this, _keyupListener, (e) => {
      const { key } = e;
      if (!this.keysUsed.includes(key)) {
        this.removeEventListener("keyup", __privateGet(this, _keyupListener));
        return;
      }
      const isButton = e.composedPath().includes(__privateGet(this, _button));
      if (isButton && (key === "Enter" || key === " ")) {
        __privateMethod(this, _toggle, toggle_fn).call(this);
      } else if (key === "Escape") {
        __privateMethod(this, _hide, hide_fn).call(this);
      }
    });
    __privateAdd(this, _keydownListener, (e) => {
      const { metaKey, altKey, key } = e;
      if (metaKey || altKey || !this.keysUsed.includes(key)) {
        this.removeEventListener("keyup", __privateGet(this, _keyupListener));
        return;
      }
      e.preventDefault();
      this.addEventListener("keyup", __privateGet(this, _keyupListener), { once: true });
    });
    __privateAdd(this, _documentClickHandler, (e) => {
      if (e.composedPath().includes(this))
        return;
      __privateMethod(this, _hide, hide_fn).call(this);
    });
    __privateAdd(this, _clickHandler, (e) => {
      if (e.composedPath().includes(__privateGet(this, _button))) {
        __privateMethod(this, _toggle, toggle_fn).call(this);
      }
    });
    __privateAdd(this, _handleOptionChange, () => {
      __privateMethod(this, _hide, hide_fn).call(this);
    });
    __privateAdd(this, _updateMenuPosition, () => {
      if (__privateGet(this, _listbox).offsetWidth === 0)
        return;
      const listboxWidth = __privateGet(this, _listbox).offsetWidth;
      const buttonRect = __privateGet(this, _button).getBoundingClientRect();
      if (this.hasAttribute("mediacontroller") || __privateGet(this, _button).hasAttribute("mediacontroller") || __privateGet(this, _listbox).hasAttribute("mediacontroller")) {
        __privateGet(this, _listbox).style.zIndex = "1";
        __privateGet(this, _listbox).style.bottom = "unset";
        __privateGet(this, _listbox).style.right = null;
        __privateGet(this, _listbox).style.left = "0";
        __privateGet(this, _listbox).style.top = `${buttonRect.height}px`;
        return;
      }
      const bounds = getBoundsElement(this);
      const boundsRect = bounds.getBoundingClientRect();
      const listboxRight = buttonRect.x + listboxWidth;
      const position = Math.max(
        buttonRect.right - listboxRight,
        buttonRect.right - boundsRect.right
      );
      __privateGet(this, _listbox).style.left = null;
      __privateGet(this, _listbox).style.right = `${position}px`;
      __privateGet(this, _listbox).style.maxHeight = `${boundsRect.height - buttonRect.height}px`;
    });
    if (!this.shadowRoot) {
      this.attachShadow({ mode: "open" });
      this.shadowRoot.appendChild(template.content.cloneNode(true));
    }
    (_a = this.init) == null ? void 0 : _a.call(this);
    __privateSet(this, _button, this.shadowRoot.querySelector("[part=button]"));
    __privateSet(this, _listbox, this.shadowRoot.querySelector("[part=listbox]"));
    __privateSet(this, _buttonSlot, this.shadowRoot.querySelector("slot[name=button]"));
    __privateGet(this, _buttonSlot).addEventListener("slotchange", () => {
      const newButton = __privateGet(this, _buttonSlot).assignedElements()[0];
      if (!newButton)
        return;
      __privateSet(this, _button, newButton);
      __privateGet(this, _button).preventClick = true;
      const disabled = this.hasAttribute("disabled") || __privateGet(this, _button).hasAttribute("disabled");
      if (disabled) {
        this.disable();
      } else {
        this.enable();
        __privateGet(this, _button).setAttribute("aria-haspopup", "listbox");
      }
    });
    __privateSet(this, _listboxSlot, this.shadowRoot.querySelector("slot[name=listbox]"));
    __privateGet(this, _listboxSlot).addEventListener("slotchange", () => {
      __privateSet(this, _listbox, __privateGet(this, _listboxSlot).assignedElements()[0] || __privateGet(this, _listbox));
    });
  }
  static get observedAttributes() {
    return [
      "disabled",
      MediaStateReceiverAttributes.MEDIA_CONTROLLER
    ];
  }
  enable() {
    __privateGet(this, _button).toggleAttribute("disabled", false);
    this.addEventListener("change", __privateGet(this, _handleOptionChange));
    this.addEventListener("keydown", __privateGet(this, _keydownListener));
    this.addEventListener("click", __privateGet(this, _clickHandler));
    document.addEventListener("click", __privateGet(this, _documentClickHandler));
  }
  disable() {
    __privateGet(this, _button).toggleAttribute("disabled", true);
    this.removeEventListener("change", __privateGet(this, _handleOptionChange));
    this.removeEventListener("keydown", __privateGet(this, _keydownListener));
    this.removeEventListener("keyup", __privateGet(this, _keyupListener));
    this.removeEventListener("click", __privateGet(this, _clickHandler));
    document.removeEventListener("click", __privateGet(this, _documentClickHandler));
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    var _a, _b, _c, _d, _e;
    if (attrName === MediaStateReceiverAttributes.MEDIA_CONTROLLER) {
      if (oldValue) {
        (_b = (_a = __privateGet(this, _mediaController)) == null ? void 0 : _a.unassociateElement) == null ? void 0 : _b.call(_a, this);
        __privateSet(this, _mediaController, null);
        __privateGet(this, _listbox).removeAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER);
      }
      if (newValue && this.isConnected) {
        __privateSet(this, _mediaController, (_c = this.getRootNode()) == null ? void 0 : _c.getElementById(newValue));
        (_e = (_d = __privateGet(this, _mediaController)) == null ? void 0 : _d.associateElement) == null ? void 0 : _e.call(_d, this);
        __privateGet(this, _listbox).setAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER, newValue);
      }
    } else if (attrName === "disabled" && newValue !== oldValue) {
      if (newValue == null) {
        this.enable();
      } else {
        this.disable();
      }
    }
  }
  connectedCallback() {
    var _a, _b, _c;
    const { style } = getOrInsertCSSRule(this.shadowRoot, ":host");
    style.setProperty("display", `var(--media-control-display, var(--${this.localName}-display, inline-flex))`);
    const mediaControllerId = this.getAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER);
    if (mediaControllerId) {
      __privateSet(this, _mediaController, (_a = this.getRootNode()) == null ? void 0 : _a.getElementById(mediaControllerId));
      (_c = (_b = __privateGet(this, _mediaController)) == null ? void 0 : _b.associateElement) == null ? void 0 : _c.call(_b, this);
      __privateGet(this, _listbox).setAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER, mediaControllerId);
    }
    if (!this.hasAttribute("disabled")) {
      this.enable();
    }
    if (!__privateGet(this, _listboxSlot).hidden) {
      observeResize(getBoundsElement(this), __privateGet(this, _updateMenuPosition));
    }
  }
  disconnectedCallback() {
    var _a, _b;
    unobserveResize(getBoundsElement(this), __privateGet(this, _updateMenuPosition));
    this.disable();
    (_b = (_a = __privateGet(this, _mediaController)) == null ? void 0 : _a.unassociateElement) == null ? void 0 : _b.call(_a, this);
    __privateSet(this, _mediaController, null);
    __privateGet(this, _listbox).removeAttribute(MediaStateReceiverAttributes.MEDIA_CONTROLLER);
  }
  get keysUsed() {
    return ["Enter", "Escape", " ", "ArrowUp", "ArrowDown", "f", "c", "k", "m"];
  }
}
_mediaController = new WeakMap();
_button = new WeakMap();
_buttonSlot = new WeakMap();
_listbox = new WeakMap();
_listboxSlot = new WeakMap();
_keyupListener = new WeakMap();
_keydownListener = new WeakMap();
_documentClickHandler = new WeakMap();
_clickHandler = new WeakMap();
_handleOptionChange = new WeakMap();
_toggle = new WeakSet();
toggle_fn = function() {
  if (__privateGet(this, _listboxSlot).hidden) {
    __privateMethod(this, _show, show_fn).call(this);
  } else {
    __privateMethod(this, _hide, hide_fn).call(this);
  }
};
_show = new WeakSet();
show_fn = function() {
  if (!__privateGet(this, _listboxSlot).hidden)
    return;
  __privateGet(this, _listboxSlot).hidden = false;
  __privateGet(this, _button).setAttribute("aria-expanded", "true");
  __privateGet(this, _updateMenuPosition).call(this);
  __privateGet(this, _listbox).focus();
  observeResize(getBoundsElement(this), __privateGet(this, _updateMenuPosition));
};
_hide = new WeakSet();
hide_fn = function() {
  if (__privateGet(this, _listboxSlot).hidden)
    return;
  unobserveResize(getBoundsElement(this), __privateGet(this, _updateMenuPosition));
  const activeElement = getActiveElement();
  __privateGet(this, _listboxSlot).hidden = true;
  __privateGet(this, _button).setAttribute("aria-expanded", "false");
  if (containsComposedNode(__privateGet(this, _listbox), activeElement)) {
    __privateGet(this, _button).focus();
  }
};
_updateMenuPosition = new WeakMap();
function getBoundsElement(host) {
  var _a;
  return (_a = host.getAttribute("bounds") ? closestComposedNode(host, `#${host.getAttribute("bounds")}`) : getMediaControllerElement(host) || host.parentElement) != null ? _a : host;
}
function getMediaControllerElement(host) {
  var _a;
  const mediaControllerId = host.getAttribute(
    MediaStateReceiverAttributes.MEDIA_CONTROLLER
  );
  if (mediaControllerId) {
    return (_a = host.getRootNode()) == null ? void 0 : _a.getElementById(mediaControllerId);
  }
  return closestComposedNode(host, "media-controller");
}
if (!globalThis.customElements.get("media-chrome-selectmenu")) {
  globalThis.customElements.define("media-chrome-selectmenu", MediaChromeSelectMenu);
}
var media_chrome_selectmenu_default = MediaChromeSelectMenu;
export {
  MediaChromeSelectMenu,
  media_chrome_selectmenu_default as default
};
