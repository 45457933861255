var __accessCheck = (obj, member, msg) => {
  if (!member.has(obj))
    throw TypeError("Cannot " + msg);
};
var __privateGet = (obj, member, getter) => {
  __accessCheck(obj, member, "read from private field");
  return getter ? getter.call(obj) : member.get(obj);
};
var __privateAdd = (obj, member, value) => {
  if (member.has(obj))
    throw TypeError("Cannot add the same private member more than once");
  member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
};
var __privateSet = (obj, member, value, setter) => {
  __accessCheck(obj, member, "write to private field");
  setter ? setter.call(obj, value) : member.set(obj, value);
  return value;
};
var __privateMethod = (obj, member, method) => {
  __accessCheck(obj, member, "access private method");
  return method;
};
var _audioTrackList, _prevState, _render, render_fn, _onChange, onChange_fn;
import { MediaChromeListbox, createOption, createIndicator } from "./media-chrome-listbox.js";
import "./media-chrome-option.js";
import { globalThis } from "../utils/server-safe-globals.js";
import { getStringAttr, setStringAttr } from "../utils/element-utils.js";
import { parseAudioTrackList } from "../utils/utils.js";
import { MediaUIAttributes, MediaUIEvents } from "../constants.js";
class MediaAudioTrackListbox extends MediaChromeListbox {
  constructor() {
    super(...arguments);
    __privateAdd(this, _render);
    __privateAdd(this, _onChange);
    __privateAdd(this, _audioTrackList, []);
    __privateAdd(this, _prevState, void 0);
  }
  static get observedAttributes() {
    return [
      ...super.observedAttributes,
      MediaUIAttributes.MEDIA_AUDIO_TRACK_LIST,
      MediaUIAttributes.MEDIA_AUDIO_TRACK_ENABLED
    ];
  }
  attributeChangedCallback(attrName, oldValue, newValue) {
    super.attributeChangedCallback(attrName, oldValue, newValue);
    if (attrName === MediaUIAttributes.MEDIA_AUDIO_TRACK_ENABLED && oldValue !== newValue) {
      this.value = newValue;
    } else if (attrName === MediaUIAttributes.MEDIA_AUDIO_TRACK_LIST && oldValue !== newValue) {
      __privateSet(this, _audioTrackList, parseAudioTrackList(newValue));
      __privateMethod(this, _render, render_fn).call(this);
    }
  }
  connectedCallback() {
    super.connectedCallback();
    this.addEventListener("change", __privateMethod(this, _onChange, onChange_fn));
  }
  disconnectedCallback() {
    super.disconnectedCallback();
    this.removeEventListener("change", __privateMethod(this, _onChange, onChange_fn));
  }
  get mediaAudioTrackList() {
    return __privateGet(this, _audioTrackList);
  }
  set mediaAudioTrackList(list) {
    __privateSet(this, _audioTrackList, list);
    __privateMethod(this, _render, render_fn).call(this);
  }
  get mediaAudioTrackEnabled() {
    return getStringAttr(this, MediaUIAttributes.MEDIA_AUDIO_TRACK_ENABLED);
  }
  set mediaAudioTrackEnabled(id) {
    setStringAttr(this, MediaUIAttributes.MEDIA_AUDIO_TRACK_ENABLED, id);
  }
}
_audioTrackList = new WeakMap();
_prevState = new WeakMap();
_render = new WeakSet();
render_fn = function() {
  if (__privateGet(this, _prevState) === JSON.stringify(this.mediaAudioTrackList))
    return;
  __privateSet(this, _prevState, JSON.stringify(this.mediaAudioTrackList));
  const audioTrackList = this.mediaAudioTrackList;
  const container = this.shadowRoot.querySelector("#container");
  container.textContent = "";
  for (const audioTrack of audioTrackList) {
    const text = this.formatOptionText(
      audioTrack.label,
      audioTrack
    );
    const option = createOption(
      text,
      `${audioTrack.id}`,
      audioTrack.enabled
    );
    option.prepend(createIndicator(this, "select-indicator"));
    container.append(option);
  }
};
_onChange = new WeakSet();
onChange_fn = function() {
  if (this.value == null)
    return;
  const event = new globalThis.CustomEvent(
    MediaUIEvents.MEDIA_AUDIO_TRACK_REQUEST,
    {
      composed: true,
      bubbles: true,
      detail: this.value
    }
  );
  this.dispatchEvent(event);
};
if (!globalThis.customElements.get("media-audio-track-listbox")) {
  globalThis.customElements.define("media-audio-track-listbox", MediaAudioTrackListbox);
}
var media_audio_track_listbox_default = MediaAudioTrackListbox;
export {
  MediaAudioTrackListbox,
  media_audio_track_listbox_default as default
};
